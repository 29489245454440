<template>
  <div class="modelEssay">
    <div class="modelBox" @click="goEssay">
      <h3>第一次做饭范文</h3>
      <p>
        在人的一生当中有许许多多的“第一次”，有时那些难忘的“第一次”可能会使你受益终身。在我的印象中，使我最难忘的是第一次做饭。
        那是很久以前的事了，虽说是这样，但我至今也难以忘怀。那一天，爸爸妈妈很早就出去有事了。我醒来后，发现他们在我的床头柜
        前留了一张字条和一盒饼干。字条上说他们今天中午才回来，特地留了一盒饼干给我吃。
        对于以前贪吃的我来说，一盒饼干一会儿就吃完了。我勉强熬了一个早上，到了中午的时候，饿得受不了了，怎么办呢，只好自己做
        饭了。我来到了厨房，发现只有一堆菠菜和几个土豆。我心想天天吃着爸爸妈妈做的饭，今天我来给他们做一顿饭吧，让他们…
      </p>
      <div class="detail-btn">查看详情></div>
    </div>
    <div class="modelBox" @click="goEssay">
      <h3>寄小读者读书笔记</h3>
      <p>
        假期我读了《寄小读者》》一书。
        　　这本书是中国现代作家冰心奶奶的代表作。《寄小读者一》共写了二十九个通讯，《再寄小读者之一》有四个通讯。《再寄小读者
        之二》写了十四个通讯。《寄小读者》里她希望自己是一个孩子，拥有儿童的天真、可爱、纯洁。和孩子们一起做游戏、讲故事。冰心
        曾为一只小老鼠钻进桌子底下吃饼干屑，被小虎子狗断送性命而十分忧愧也叙述了她从北京到海外的风光和奇事。对母亲的爱和祖国的
        思念。《寄小读者之一》她写的是友谊。是人和人之间的关系最宝贵。朋友不分年龄和性别。他愿意和我们交朋友，让我接受她吧。在
        《寄小读者之二》教导我们要学好语文，因为语文是一门基础知识，学好语文，我们才能读书看报。阅读和写作才能不断提高…
      </p>
      <div class="detail-btn">查看详情></div>
    </div>
    <div class="modelBox" @click="goEssay">
      <h3>读《鲁滨逊漂流记》有感</h3>
      <p>
        《鲁滨逊漂流记》，这本书的主要内容是：一个叫鲁滨逊的人，他从小就非常喜欢在海上漂流，有一次，他趁父母不在家时自己悄悄的
        出海了，结果他在海上不幸遇到了风浪，被漂流到了一个荒无人烟的小岛上，他长达二十八年的生活就开始了。她自己学会了制做粗糙
        的面包、家具、生活用品……鲁滨逊聪明机智、勇敢顽强的精神，使我深深受益。我读完这本书明白了一个道理：无论做什么事情都不
        能依靠别人，要尽力做好分内的事情和自己应该做的事情。
        在生活中，自己不会做的事情要学着做，会做的事情一定要尽力做好。所以依靠别人会使自己变得胆小懦弱，如果不依靠别人…
      </p>
      <div class="detail-btn">查看详情></div>
    </div>
    <div class="modelBox" @click="goEssay">
      <h3>假如给我三天光明优秀阅读笔记</h3>
      <p>
        看完这本书，我顿时感到无比的乐活。海伦，用自己的坚持，用自己的信念，用父母以及莎莉文老师的陪同，实现了梦想。
        梦想，只要努力过，它不是梦，也不是想，而是由梦和想与自己的努力变成的现实。多么美好啊，多么让人觉得兴奋和幸福啊!连聋哑
        人海伦，一个曾经是连一个字都不认识的小女孩，变成了从一个哈佛大学毕业的大学生，这是多么了不起的奇迹?
        海伦学会了五国的语言，学会了说话，还有什么奇迹是我们做不到的?她从人生的低潮，登上了人生的无比高的高潮。而我们也没有经
        历过像海伦人生最失落的低潮。我们却也可以凭自己的努力登上那美好的高潮…
      </p>
      <div class="detail-btn">查看详情></div>
    </div>
    <div class="modelBox" @click="goEssay">
      <h3>以乐观话题的作文</h3>
      <p>
        即使暴雨来袭，微微侧视，蓝天依旧如此耀眼。“阳光总在风雨后，请相信有彩虹。”歌曲唱出了我们的心声，告诉我们做任何事情都不
        会一帆风顺，要学会逆水行舟，用积极乐观的心态面对生活。
        “仁孝行于家，13年相守，有多少个日子就有多少道坎坷，命运百般挤兑，你总咬紧牙关，寒风带着雪花，围攻最北方的一角。这小
        小的车库，是冬天里最温暖的宫殿，你病重的老父亲是最幸福的王。”这是给2015年度感动中国人物之一的孝女朱晓珲的颁奖词。
        13年的艰苦生活，她没有皱缩眉头，依然乐观笑对生活。照顾父亲的闲暇之余，她会来到图书馆，读读书，领悟书本的诗意…
      </p>
      <div class="detail-btn">查看详情></div>
    </div>
    <div class="modelBox" @click="goEssay">
      <h3>五年级作文：观夏雨</h3>
      <p>
        期天，天空灰蒙蒙一片，一场夏雨即将来袭。 刚开始，迎面吹来一股凉风，接着豆大的雨点从灰蒙蒙的天上一个一个落了下来。不一会，天越来越暗，一片片乌云开始笼罩天空，风
        也越来越大，小草嫩绿的身上沾满了泥水，挺直的小树苗也被风吹得弯下了腰。雨点变成了一串串晶莹剔透的珍珠，落在窗户上发出“滴
        滴答答”清脆又动人的声音，像一位技艺高超的音乐家演奏的美妙乐章。突然，一道闪电从天边划过，仿佛一只怪兽的手要把地面抓起来
        ，实在太恐怖了！接着，大雨像下饺子似的从天上落下来，砸在泥土上，瞬间就变成泥水；砸在树叶、小草、花瓣上，就立刻变成…
      </p>
      <div class="detail-btn">查看详情></div>
    </div>
    <div class="modelBox" @click="goEssay">
      <h3>关于努力的作文</h3>
      <p>
        努力的人会收获许多意想不到的成功，谁都不是天才，所以我们需要努力。努力让自己成为天才。天才也需要努力。
        居里夫人在法国念书时，每天早晨总是第一个来到教室;每天晚上几乎都在图书室度过。图书室10点关门，回到自己的小屋后，她在煤
        油灯下继续读书，常常到夜里一两点钟。不仅居里夫人如此，许多成功者的事实都可以证明，勤奋可以创造成功，有天资的人要有作为
        是离不开勤奋的。
        梅兰芳年轻时拜师学戏，但师傅说他根本不是学戏的料，不肯收留。为了使自己补足天资的缺陷，他反而更加勤奋。他喂鸽子，每…
      </p>
      <div class="detail-btn">查看详情></div>
    </div>
    <div class="modelBox" @click="goEssay">
      <h3>关于春节的小学生作文</h3>
      <p>
        在我的记忆中，每年的春节都是差不多的，热闹、喜庆而祥和。
        春节的前几天，是大人们最忙的时候，张罗这张罗那，但是，却是我们小孩子最憧憬的时候。爸爸妈妈买了许多好吃的，而我却只能眼
        巴巴地看着，可望而不可即，于是，便急切地盼望着春节的到来 。
        除夕那一天晚上，当然也是我家最开心的时候，全家三口人一齐静静地看着春节联欢晚会，偶尔外面传来几声鞭炮声，也会觉得特别有
        趣。电视里优美的舞蹈，搞笑的小品常常逗得我们哈哈大笑，这笑
        声伴随着窗外的鞭炮声，显得气氛愈加欢乐…
      </p>
      <div class="detail-btn" >查看详情></div>
    </div>
    <div class="modelBox" @click="goEssay">
      <h3>以清晨为话题的作文400字</h3>
      <p>
        清早，我从家里一出来，就闻到一阵淡淡的花香，我赶紧往花坛边走去。
        花坛里的花开得特别多，万紫千红，千姿百态：有的是金黄色的，有的是紫色的，有的白里透着粉红，还有大红色和玫瑰红的……它们朵
        朵亭亭玉立，象一个个俊俏的少女。
        这么多的花，一朵有一朵的姿势。看看这一朵，很美;看看那一朵，也很美。再配上花坛边几棵高大碧绿的雪松衬托，显得更美了。如果
        把眼前的这一坛五颜六色的鲜花看作一大幅活的画，那画家的本领可真了不…
      </p>
      <div class="detail-btn" >查看详情></div>
    </div>
    <div class="modelBox" @click="goEssay">
      <h3>三国演义五年级读后笔记</h3>
      <p>
        这个暑假，我阅读了一本名叫《三国演义》的书，这本书中有许多英雄，如粗中有细的张飞，忠肝义胆的关羽，谦虚好学的刘备，足智
        多谋的诸葛亮，其中我对诸葛亮影响最深刻。因为他用智慧打了许多胜仗。
        以下是诸葛亮一生的功绩;收二川
        、摆八阵、六出七擒、五丈原前点49盏明灯，一心只为酬三顾，东和北拒，中军帐里，水面偏能用火攻
        。收二川：指取得东川和西川。摆八阵：摆设八阵图。六出：六出祁山。七擒：七擒孟获。三顾：三顾茅庐。东和：东和孙吴。北拒：
        北拒曹魏。水面偏能用火攻;在赤壁大战中让黄盖坐二十条快船去假投降魏国，船上放满了干草，到了曹操的大本营，二十条船一…
      </p>
      <div class="detail-btn">查看详情></div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      list: []
    };
  },
  methods: {
    goEssay() {
      this.$router.push({ name: "essayDetail" });
    }
  }
};
</script>

<style lang="scss" scoped>
.modelEssay {
  width: 1120px;
  margin: 0 auto;
  .modelBox {
    padding: 35px 23px;
    box-shadow: 0px 14px 30px 0px rgba(236, 235, 235, 1);
    margin: 32px 0;
    cursor: pointer;
    h3 {
      font-size: 36px;
      font-family: MicrosoftYaHeiUI-Bold, MicrosoftYaHeiUI;
      font-weight: bold;
      color: rgba(68, 68, 68, 1);
      text-align: left;
      margin-bottom: 20px;
    }
    p {
      font-size: 18px;
      font-family: MicrosoftYaHeiUI;
      color: rgba(68, 68, 68, 1);
      text-align: left;
      line-height: 30px;
    }
    .detail-btn {
      margin-top: 20px;
      text-align: right;
      font-size: 16px;
      font-family: MicrosoftYaHeiUI;
      color: rgba(255, 112, 65, 1);
      cursor: pointer;
    }
  }
}
</style>
